import React from 'react';
import ReactDOM from 'react-dom';
import Home from './pages/Home';
import { ThemeProvider } from 'styled-components';
import theme from './components/Styled/Theme';
import Global from './components/Styled/Global';

ReactDOM.render(
  <React.StrictMode>
    <Global />
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
