import styled, { css } from 'styled-components';
import Navigation from '../components/Navigation/Navigation';
import Recipe from '../components/Recipe/Recipe';
import RecipeCard from '../components/RecipeCard/RecipeCard';
import Profile from '../components/Profile/Profile';
import { useState } from 'react';
import jsonp from 'jsonp';
import { CheckCircleIcon } from '@iconicicons/react';

const sampleImg = "https://www.shopcanton.org/wp-content/uploads/2020/03/Takeoutshutterstock_1072334861.jpg";
const pancakes = "https://images.unsplash.com/photo-1532980400857-e8d9d275d858?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80";

const recipes = [
    {
        background: sampleImg,
        favorites: 23
    },
    {
        background: sampleImg,
        favorites: 12
    },
    {
        background: sampleImg,
        favorites: 55
    },
    {
        background: sampleImg,
        favorites: 44
    },
    {
        background: sampleImg,
        favorites: 14
    },
]

const Home = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        const action = "https://niibble.us6.list-manage.com/subscribe/post?u=1c81b8eb85486c9b65fd3b0e4&amp;id=991385851f";
        const path = `${action}&EMAIL=${email}`;
        const url = path.replace('/post?', '/post-json?');
        const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
        (!regex.test(email)) ? setStatus('empty') : sendData(url);
    }

    const sendData = (url) => {
        setStatus("sending");
        jsonp(url, { param: "c" }, (err, data) => {
            if (data.msg.includes("already subscribed")) {
                setStatus('duplicate');
            } else if (err) {
                setStatus('error');
            } else if (data.result !== 'success') {
                setStatus('error');
            } else {
                setStatus('success');
                setEmail('');
            };
        });
    }

    return (
        <Container>
            <Navigation />
            <ContentContainer>
                <div style={{ width: '100%' }}>
                    <SubTitle>We're still in the oven.</SubTitle>
                    <Title>Get notified when we launch.</Title>
                    
                    <InputContainer>
                        <Input error={status === 'error' || status === 'empty'} placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/>
                        <Button onClick={() => handleSubmit()}>Subscribe</Button>
                    </InputContainer>

                    {status === 'error' || status === 'empty' ? <ErrorMessage>Please enter a valid email address.</ErrorMessage> : null}
                    {status === 'duplicate' ? <InfoMessage>Looks like you're already signed up, we'll be in touch!</InfoMessage> : null}

                    {status === 'success'
                        ?
                    <StatusMessage><CheckCircleIcon />You're subscribed to Niibble updates!</StatusMessage>
                        :
                    null}
                </div>
                <ImagesContainer>
                    <RecipeCard background={pancakes} />
                    <Profile />
                </ImagesContainer>
            </ContentContainer>
            {/* <RecipesContainer>
                {recipes.map((recipe) => {
                    return(
                        <Recipe favorites={recipe.favorites} background={recipe.background} />
                    )
                })}
            </RecipesContainer> */}
        </Container>
    );
}

export default Home;

const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const ContentContainer = styled.div`
    height: 100%;
    display: flex;
    padding: 5rem 10%;
    box-sizing: border-box;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        flex-direction: column;
        padding: 5rem 2rem;
    }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-top: 0.5rem;
`;

const InfoMessage = styled.span`
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-top: 0.5rem;
`;

const StatusMessage = styled.span`
    display: inline-block;
    margin-top: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    background: #EEE;
    width: 100%;
    border-radius: 0.25rem;
    overflow: hidden;


    svg {
        margin-right: 1rem;
        background: #4BB543;
        color: white;
        padding: 0.5rem;
    }
`;

const ImagesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        margin-top: 20px;
    }
`;

const RecipesContainer = styled.div`
    width: 100%;
    overflow: scroll-x;
`;

const SubTitle = styled.span`
    color: ${p => p.theme.colors.pink};
    font-weight: bold;
    font-size: 2rem;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        display: block;
    }
`;

const Title = styled.h1`
    font-size: 60px;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        font-size: 2.5rem;
        line-height: 1;
    }
`;

const InputContainer = styled.div`
    margin-top: 3rem;
    display: flex;
    width: 100%;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        flex-direction: column;
    }
`;

const Input = styled.input`
    font-family: "Gilroy", Helvetica;
    background: #EDEDED;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 1rem;
    min-width: 350px;
    transition: ${p => p.theme.utility.transition};
    box-sizing: border-box;
    width: 100%;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        width: 100%;
        min-width: 200px;
        margin-bottom: 1rem;
    }

    ${p => p.error && css`
        border: 2px solid red;
    `}
`;

export const Button = styled.button`
    font-family: "Gilroy", Helvetica;
    color: white;
    font-size: 18px;
    background: ${p => p.theme.colors.pink};
    font-weight: 600;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: ${p => p.theme.utility.transition};

    &:hover {
        background: #E60F57;
        transition: ${p => p.theme.utility.transition};
    }

    @media (max-width: ${p => p.theme.queries.mobile}) {
        width: 100%;
    }
`;
