import styled from 'styled-components';
import {
    ArrowLeftIcon,
    DotsHorizontalIcon
} from '@iconicicons/react';

const RecipeCard = (props) => {
    return(
        <RecipeCardContainer background={props.background}>
            <TopBar>
                <ArrowLeftIcon />
                <DotsHorizontalIcon />
            </TopBar>
            <Content>
                <RecipeTitle>
                    <h2>{props.title || "Chocolate & Mixed Berry Pancakes"}</h2>
                </RecipeTitle>
            </Content>
        </RecipeCardContainer>
    )
}

export default RecipeCard;

const RecipeCardContainer = styled.div`
    width: 375px;
    box-sizing: border-box;
    border-radius: 1rem;
    overflow: hidden;
    background: gray url(${p => p.background}) no-repeat center;
    background-size: cover;
    position: relative;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        width: 350px;
    }
`;

const TopBar = styled.div`
    background: ${p => p.theme.colors.black};
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    margin-bottom: -1rem;
    z-index: 100;
`;

const Content = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 2rem;
    height: 300px;
`;

const RecipeTitle = styled.div`
    font-size: 2rem;
    background: linear-gradient(transparent, black);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem;

    h2 {
        font-size: 2rem;
        color: white;
    }
`;
