import styled from 'styled-components';
import { HeartIcon } from '@iconicicons/react';

const Recipe = (props) => {
    return(
        <RecipeContainer background={props.background}>
            <Favorites>
                <HeartIcon />
                {props.favorites || '100'}
            </Favorites>
        </RecipeContainer>
    )
}

export default Recipe;

const RecipeContainer = styled.div`
    background: gray url(${p => p.background}) no-repeat center;
    background-size: cover;
    border-radius: 0.75rem;
    height: 150px;
    width: 160px;
    position: relative;
    overflow: hidden;
    margin-right: 1rem;
    display: inline-block;
    transition: ${p => p.theme.utility.transition};

    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.35);
    }
`;

const Favorites = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, black);
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.25rem;
    }
`;