import { createGlobalStyle } from "styled-components";
import background from '../../assets/img/background.png';

const Global = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }

    body {
        font-family: "Gilroy", Helvetica;
        height: 100vh;
        width: 100vw;
        background: url(${background});
    }
`

export default Global;