import styled from 'styled-components';

const Logo = (props) => {
    return(
        <LogoSVG height={"1.5rem" || props.width} x="0px" y="0px" viewBox="0 0 145 32">
            <g>
                <path d="M37.6,0h-7.4v7.3h7.3l-7.3,7.3v16.7h7.4V0L37.6,0z"/>
                <path d="M48.5,0h-7.4v7.3h7.3l-7.3,7.3v16.7h7.4V0L48.5,0z"/>
                <path d="M7.4,7.3H0v7.3h7.3L0,21.9v9.4h7.4V7.3L7.4,7.3z M14.5,7.5l-7.2,7.1H12c4.1,0,7.4,3.3,7.4,7.3v9.4h7.4v-9.4
                    C26.7,14.7,21.4,8.7,14.5,7.5L14.5,7.5z"/>
                <path d="M77.4,19.7c0,7-5.1,12.3-11.2,12.3c-3.1,0-5.5-1.1-7-2.9v2.2h-7V0h7v10.2c1.6-1.8,3.9-2.9,7-2.9
                    C72.3,7.3,77.4,12.7,77.4,19.7z M70.4,19.7c0-3.5-2.3-5.7-5.6-5.7c-3.2,0-5.6,2.2-5.6,5.7c0,3.5,2.3,5.7,5.6,5.7
                    C68,25.4,70.4,23.2,70.4,19.7z"/>
                <path d="M106.3,19.7c0,7-5.1,12.3-11.2,12.3c-3.1,0-5.5-1.1-7-2.9v2.2h-7V0h7v10.2c1.6-1.8,3.9-2.9,7-2.9
                    C101.2,7.3,106.3,12.7,106.3,19.7z M99.2,19.7c0-3.5-2.3-5.7-5.6-5.7c-3.2,0-5.6,2.2-5.6,5.7c0,3.5,2.3,5.7,5.6,5.7
                    C96.9,25.4,99.2,23.2,99.2,19.7z"/>
                <path d="M109.9,0h7v31.3h-7V0z"/>
                <path d="M133.7,25.7c1.9,0,3.6-0.7,4.5-1.8l5.6,3.2c-2.3,3.2-5.8,4.8-10.2,4.8c-8,0-13-5.4-13-12.3
                    c0-7,5.1-12.3,12.5-12.3c6.9,0,11.9,5.3,11.9,12.3c0,1-0.1,1.9-0.3,2.8H128C128.9,24.9,131.1,25.7,133.7,25.7z M138,17.2
                    c-0.8-2.7-2.9-3.7-5-3.7c-2.7,0-4.5,1.3-5.1,3.7H138z"/>
            </g>
        </LogoSVG>
    )
}

export default Logo;

const LogoSVG = styled.svg`
    fill: ${p => p.theme.colors.pink};
`;