function hexToRgbA(hex, alpha) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
  
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
        ","
      )},${alpha})`;
    }
    throw new Error("Bad Hex");
}

const theme = {
    colors: {
        pink: '#FF1161',
        orange: '#FF5B55',
        gradient: `linear-gradient(${hexToRgbA('#FF1161')}, ${hexToRgbA('#FF5B55')})`,
        black: '#0E0E0E'
    },
    queries: {
        mobile: '775px'
    },
    utility: {
        transition: 'all cubic-bezier(0.16, 0.8, 0.45, 1) 0.5s'
    }
}

export default theme;