import styled from 'styled-components';
import Logo from '../Logo/Logo';

const Navigation = (props) => {
    return(
        <NavContainer>
            <Logo />
        </NavContainer>
    )
}

export default Navigation;

const NavContainer = styled.nav`
    width: 100%;
    padding: 3rem;
    box-sizing: border-box;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        padding: 2rem;
    }
`;