import styled, { css } from 'styled-components';

const Profile = (props) => {
    return(
        <ProfileContainer>
            <ProfileName>{props.name || "Dwight Schrute"}</ProfileName>
            <ProfileDescription>{props.description || "Recipes brought to you straight from the world famous beet farm."}</ProfileDescription>
        
            <ButtonGroup>
                <Button>Follow</Button>
                <Button secondary>Message</Button>
            </ButtonGroup>
        </ProfileContainer>
    )
}

export default Profile;

const ProfileContainer = styled.div`
    width: 330px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 1rem;
    backdrop-filter: blur(0.25rem);
    margin-top: -4.5rem;
    margin-left: 10rem;

    @media (max-width: ${p => p.theme.queries.mobile}) {
        width: 300px;
        margin-top: -2.5rem;
        margin-left: 0;
    }
`;

const ProfileName = styled.h3`
    font-size: 21px;
    margin-bottom: 0.5rem;
`;

const ProfileDescription = styled.p`
    color: #D0CECA;
    font-size: 14px;
    text-align: center;
    margin-bottom: 1rem;  
`;

const ButtonGroup = styled.div`
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    width: 100%;
`;

export const Button = styled.button`
    font-family: "Gilroy", Helvetica;
    color: white;
    font-size: 14px;
    background: ${p => p.theme.colors.pink};
    font-weight: 600;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    transition: ${p => p.theme.utility.transition};

    &:hover {
    }

    ${p => p.secondary && css`
        background: rgba(255, 255, 255, 0.1);

        &:hover {
        }
    `}
`;

// follow btn hover
// background: #E60F57;
// transition: ${p => p.theme.utility.transition};


// msg btn hover
// background: rgba(255, 255, 255, 0.2);
